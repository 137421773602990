import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@material-ui/core';

import i18n from '../i18n';
import StatusContext from '../contexts/statusContext';
import getStorage from '../utils/getStorage';
import setStorage from '../utils/setStorage';
import ReceiptImgV2 from '../components/receiptImgV2';

const SuccessContainer = ({ payToken }) => {
  const { t } = useTranslation();
  const { setStage } = useContext(StatusContext);
  const [recieptURL, setRecieptURL] = useState('');

  const back = () => {
    setStage('pay');
    window.location.replace(`${process.env.REACT_APP_ROUTE_PATH}/`);
  };

  useEffect(() => {
    setStage('success');
    setStorage('paid', [...getStorage('selected')]);
  }, []);

  return (
    <>
      {recieptURL ? (
        <img alt="" src={recieptURL} className="reciept-img" />
      ) : (
        <div className={classNames('success-container')}>
          <div className={classNames('success-title')}>
            {t('success.success')}
          </div>
          <div className={classNames('success-item-list')}>
            {getStorage('selected').map((item) => (
              <div className={classNames('success-item')}>
                <div>{t('success.item')}</div>
                <div>
                  {i18n.language === 'en-US' ? item.item_ename : item.item_name}
                </div>
              </div>
            ))}
            <div className={classNames('success-item')}>
              <div>{t('success.amount')}</div>
              <div>
                {getStorage('selected').reduce(
                  (sum, item) => sum + parseInt(item.apply_amt, 10),
                  0,
                )}
              </div>
            </div>
          </div>
          <div className={classNames('success-warning')}>
            {t('success.warning')}
          </div>
          <ReceiptImgV2 payToken={payToken} passRecieptURL={setRecieptURL} />
          <Button
            className={classNames('back')}
            type="button"
            onClick={() => back()}
          >
            {t('success.back')}
          </Button>
        </div>
      )}
    </>
  );
};

SuccessContainer.propTypes = {
  payToken: PropTypes.string.isRequired,
};

export default SuccessContainer;
