import React from 'react';
import PropTypes from 'prop-types';
import CompleteContainer from '../containers/complete';

const CompletePage = ({ match }) => (
  <CompleteContainer result={match.params.result} />
);

CompletePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      result: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CompletePage;
