import React, { useRef, useEffect, useState, useCallback } from 'react';
import { toPng } from 'html-to-image';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button } from '@material-ui/core';

import getStorage from '../utils/getStorage';
import queryOrder from '../apis/queryOrder';

const receiptImgV2 = ({ payToken, passRecieptURL }) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const receiptRef = useRef();
  const bills = getStorage('selected');
  const onButtonClick = useCallback(async () => {
    if (receiptRef.current === null) {
      return;
    }

    toPng(receiptRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        passRecieptURL(dataUrl);
      })
      .then(setTimeout(() => {}, 10000));
  }, [receiptRef]);

  useEffect(async () => {
    setIsLoading(true);
    const res = await queryOrder({
      SID: getStorage('studentId'),
      PayToken: payToken,
    });
    if (res) {
      setInfo(res);
      setIsLoading(false);
    } else {
      // eslint-disable-next-line no-alert
      alert(t('receipt.error'));
    }
  }, []);

  return (
    <>
      <div className={classNames('receipt-container')}>
        <div className={classNames('receipt-v2')} ref={receiptRef}>
          <img
            className={classNames('receipt-v2-bg')}
            alt="receipt-bg"
            src={`${process.env.REACT_APP_ROUTE_PATH}/receipt-v2.png`}
          />
          <div className={classNames('receipt-v2-content')}>
            <div className={classNames('receipt-v2-date')}>
              <span>{dayjs(info.ApplyDate).format('YYYY/MM/DD HH:mm:ss')}</span>
            </div>
            <table className={classNames('receipt-v2-table')}>
              <colgroup>
                <col span={1} style={{ width: '30%' }} />
                <col span={1} style={{ width: '27%' }} />
                <col span={1} style={{ width: '18%' }} />
                <col span={1} style={{ width: '25%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <td> </td>
                  <td colSpan="3">
                    {bills.map((item) => `${item.dept_name} `)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <br />
                    <br />
                  </td>
                  <td>{getStorage('name')}</td>
                  <td>
                    <br />
                  </td>
                  <td>{getStorage('studentId')}</td>
                </tr>
                <tr>
                  <td> </td>
                  <td colSpan="3">{getStorage('identityId')}</td>
                </tr>
                <tr>
                  <td> </td>
                  <td colSpan="3">
                    {bills.map((item) => `${item.item_name} `)}
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td colSpan="3">
                    {bills.reduce(
                      (sum, item) => sum + parseInt(item.apply_amt, 10),
                      0,
                    )}{' '}
                    元
                  </td>
                </tr>
                <tr>
                  <td className={classNames('p-0')}>
                    <br />
                  </td>
                  <td colSpan="3" className={classNames('p-0')}>
                    <span className={classNames('remark-v2-payment-date')}>
                      {dayjs(info.PayDate).format('YYYY/MM/DD')}
                    </span>
                    <br />
                    <span className={classNames('remark-v2-payment-type')}>
                      {info.PayType === 'C' ? '信用卡支付' : '手機支付'}
                    </span>
                    <br />
                    <span className={classNames('remark-v2-serial-number')}>
                      {info.PayNo}
                    </span>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Button
        className={classNames('download')}
        type="button"
        disabled={isLoading}
        onClick={onButtonClick}
      >
        {isLoading ? `${t('receipt.processing')}...` : t('receipt.download')}
      </Button>
    </>
  );
};

receiptImgV2.propTypes = {
  payToken: PropTypes.string.isRequired,
  passRecieptURL: PropTypes.func.isRequired,
};

export default receiptImgV2;
