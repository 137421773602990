import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@material-ui/core';

import i18n from '../i18n';
import StatusContext from '../contexts/statusContext';
import getStorage from '../utils/getStorage';
import setStorage from '../utils/setStorage';

const CompleteContainer = ({ result }) => {
  const { t } = useTranslation();
  const { setStage } = useContext(StatusContext);
  const [time, setTime] = useState(5);
  const [hint, setHint] = useState('');

  const back = () => {
    setStage('pay');
    window.location.replace(`${process.env.REACT_APP_ROUTE_PATH}/`);
  };

  useEffect(() => {
    setStage(result);
    setInterval(() => {
      setTime((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
  }, []);

  useEffect(() => {
    if (result === 'failed') {
      if (i18n.language === 'zh-TW') {
        setHint(
          `${time}秒鐘後將自動跳轉回待繳項目頁面，若未跳出請按下方按鈕自行跳轉`,
        );
      } else {
        setHint(
          `It'll will automatically redirect to home page in ${time} seconds. If it doesn't, click the button below`,
        );
      }
      if (time === 0) back();
    }
  }, [time, result]);

  useEffect(() => {
    if (result === 'success') {
      const bills = getStorage('bills');
      const selected = getStorage('selected');
      selected.forEach((item) => {
        // eslint-disable-next-line camelcase
        if (bills.find(({ apply_no }) => apply_no === item.apply_no)) {
          bills.splice(bills.indexOf(item), 1);
        }
      });
      setStorage('bills', bills);
    }
  }, [result]);

  return (
    <div className={classNames('complete-container', `${result}`)}>
      {result === 'failed' ? (
        <>
          <div className={classNames('complete-title-failed')}>
            {result === 'success'
              ? t('complete.success')
              : t('complete.failed')}
          </div>
          <div className={classNames('complete-hint')}>{hint}</div>
        </>
      ) : (
        <>
          <div className={classNames('complete-title-success')}>
            {t('complete.success')}
          </div>
          <div className={classNames('complete-item-list')}>
            {getStorage('selected').map((item) => (
              <div className={classNames('complete-item')}>
                <div>{t('complete.item')}</div>
                <div>
                  {i18n.language === 'en-US' ? item.item_ename : item.item_name}
                </div>
              </div>
            ))}
            <div className={classNames('complete-item')}>
              <div>{t('complete.amount')}</div>
              <div>
                {getStorage('selected').reduce(
                  (sum, item) => sum + parseInt(item.apply_amt, 10),
                  0,
                )}
              </div>
            </div>
          </div>
          <div className={classNames('complete-warning')}>
            {t('complete.warning')}
          </div>
          <Button className={classNames('download')} type="button">
            {t('complete.download')}
          </Button>
        </>
      )}
      <Button
        className={classNames('back')}
        type="button"
        onClick={() => back()}
      >
        {t('complete.back')}
      </Button>
    </div>
  );
};

CompleteContainer.propTypes = {
  result: PropTypes.string.isRequired,
};

export default CompleteContainer;
