/* eslint-disable no-alert */
import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
// import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import setStorage from '../utils/setStorage';
import loginAndGetBills from '../apis/loginAndGetBills';
import StatusContext from '../contexts/statusContext';
import Lng from '../components/lng';

const LoginContainer = () => {
  const { t } = useTranslation();
  const { setStage } = useContext(StatusContext);
  const [country, setCountry] = useState('');
  const [hint, setHint] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onSubmit = async (values) => {
    const res = await loginAndGetBills({
      Local: country === 'local' ? 'true' : 'false',
      SID: values.studentId,
      PID: values.identityId,
    });
    if (res) {
      setStorage('isLocal', country === 'local' ? 'true' : 'false');
      setStorage('studentId', values.studentId);
      setStorage('identityId', values.identityId);
      setStorage('name', res.name);
      setStorage('ename', res.ename);
      setStorage('bills', res.bills);
      setStage('pay');
    }
  };

  // const validationSchema = yup.object({
  //   studentId: yup
  //     .string(t('login.enter_student_id'))
  //     // .matches(/^[a-zA-Z][0-9]+$/, t('common.wrong_format'))
  //     .min(9, t('common.wrong_format'))
  //     .max(9, t('common.wrong_format'))
  //     .required(t('common.required')),
  //   identityId: yup
  //     .string(t('login.enter_identity_id'))
  //     // .matches(/^[a-zA-Z][0-9]+$/, t('common.wrong_format'))
  //     .min(10, t('common.wrong_format'))
  //     .max(10, t('common.wrong_format'))
  //     .required(t('common.required')),
  // });

  const formik = useFormik({
    initialValues: {
      studentId: '',
      identityId: '',
    },
    // validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (country === '') {
      setHint(t('login.select_identity'));
    } else if (country === 'local') {
      setHint(t('login.local_login'));
    } else {
      setHint(t('login.foreign_login'));
    }
  }, [country, t]);

  return (
    <>
      <form
        className={classNames('login-container')}
        onSubmit={formik.handleSubmit}
      >
        <div className={classNames('login-hint')}>{hint}</div>
        {country === '' && (
          <>
            <Button
              className={classNames('login-country-btn')}
              onClick={() => setCountry('local')}
            >
              {t('login.local_stu')}
            </Button>
            <Button
              className={classNames('login-country-btn')}
              onClick={() => setCountry('foreign')}
            >
              {t('login.foreign_stu')}
            </Button>
          </>
        )}
        {country !== '' && (
          <TextField
            name="studentId"
            label={t('login.student_id')}
            value={formik.values.studentId}
            onChange={formik.handleChange}
            error={formik.touched.studentId && Boolean(formik.errors.studentId)}
            helperText={formik.touched.studentId && formik.errors.studentId}
            fullWidth
            variant="outlined"
            type="text"
            size="small"
          />
        )}
        {country === 'local' && (
          <TextField
            name="identityId"
            label={t('login.identity_id')}
            value={formik.values.identityId}
            onChange={formik.handleChange}
            error={
              formik.touched.identityId && Boolean(formik.errors.identityId)
            }
            helperText={formik.touched.identityId && formik.errors.identityId}
            fullWidth
            variant="outlined"
            type="text"
            size="small"
          />
        )}
        {country !== '' && (
          <div className={classNames('login-top-btns-container')}>
            <Button
              className={classNames('login-back-btn')}
              variant="outlined"
              onClick={() => setCountry('')}
            >
              {t('login.back')}
            </Button>
            <Button
              className={classNames('login-submit-btn')}
              type="submit"
              variant="outlined"
            >
              {t('login.login')}
            </Button>
          </div>
        )}
        <div className={classNames('login-bottom-btns-container')}>
          <div className={classNames('login-lng-down')}>
            <Lng />
          </div>
          <Button
            className={classNames('login-info-btn')}
            onClick={() => setIsDialogOpen(true)}
          >
            <InfoIcon style={{ marginRight: '10px' }} />
            {t('login.info')}
          </Button>
        </div>
      </form>
      <div className={classNames('login-lng-top')}>
        <Lng />
      </div>
      <Dialog open={isDialogOpen}>
        <DialogTitle>成大未來智慧工場 Atelier Future</DialogTitle>
        <DialogContent>
          <DialogContentText>
            帳務問題-出納組 #50606~50610
          </DialogContentText>
           <DialogContentText>
            繳費資料核對-計網中心 #61033
          </DialogContentText>
          <DialogContentText>
            系統改善建議-未來智慧工場 #80920-80921
          </DialogContentText>
          <DialogContentText>Email：af.proj@gs.ncku.edu.tw</DialogContentText>
          <DialogContentText>
            校友服務-畢業證書暨成績單申請系統 https://campus4.ncku.edu.tw/eCharge/
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>關閉</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoginContainer;
