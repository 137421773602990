/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button, CircularProgress } from '@material-ui/core';

import i18n from '../i18n';
import getStorage from '../utils/getStorage';
import StatusContext from '../contexts/statusContext';
import pay from '../apis/pay';
import CheckoutItem from '../components/checkoutItem';

const CheckoutContainer = () => {
  const { t } = useTranslation();
  const { setStage } = useContext(StatusContext);
  const [cardHover, setCardHover] = useState(false);
  const [phoneHover, setPhoneHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bills = getStorage('selected');

  const payWith = async (type) => {
    if (type === 'card') {
      setIsLoading(true);
      const url = await pay({
        SID: getStorage('studentId'),
        ApplyNo: `${bills.map((item) => item.apply_no)}`.replace(/,/g, '_'),
        Amt: `${bills.reduce(
          (sum, item) => sum + parseInt(item.apply_amt, 10),
          0,
        )}`,
        PayType: 'C',
        ExpireDate: dayjs().add(1, 'day').format('YYYYMMDD'),
        AutoBill: 'Y',
      });
      if (url) {
        window.location.replace(url);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      const url = await pay({
        SID: getStorage('studentId'),
        ApplyNo: `${bills.map((item) => item.apply_no)}`.replace(/,/g, '_'),
        Amt: `${bills.reduce(
          (sum, item) => sum + parseInt(item.apply_amt, 10),
          0,
        )}`,
        PayType: 'M',
        ExpireDate: dayjs().add(1, 'day').format('YYYYMMDD'),
      });
      if (url) {
        window.location.replace(url);
      } else {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress style={{ color: '#a31f34' }} />;
  }

  return (
    <div className={classNames('checkout-container')}>
      <div className={classNames('checkout-title-info')}>
        {t('checkout.info')}
      </div>
      <div className={classNames('checkout-amount-container')}>
        <div>{t('checkout.total')}</div>
        <div className={classNames('checkout-amount-dollar')}>
          ${bills.reduce((sum, item) => sum + parseInt(item.apply_amt, 10), 0)}
        </div>
      </div>
      <div className={classNames('checkout-items-container')}>
        {bills.map((item, index) => (
          <CheckoutItem
            index={index}
            name={i18n.language === 'en-US' ? item.item_ename : item.item_name}
            value={item.apply_amt}
            memo={item.apply_memo}
          />
        ))}
      </div>
      <div className={classNames('checkout-title-method')}>
        {t('checkout.method')}
      </div>
      <Button
        type="button"
        onClick={() => payWith('card')}
        onMouseOver={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
        size="small"
      >
        <img
          alt="NCKU"
          src={`${process.env.REACT_APP_ROUTE_PATH}/${
            cardHover ? 'white-card' : 'grey-card'
          }.svg`}
        />
        {t('checkout.credit_card')}
      </Button>
      <Button
        type="button"
        onClick={() => payWith('phone')}
        onMouseOver={() => setPhoneHover(true)}
        onMouseLeave={() => setPhoneHover(false)}
        size="small"
      >
        <img
          alt="NCKU"
          src={`${process.env.REACT_APP_ROUTE_PATH}/${
            phoneHover ? 'white-phone' : 'grey-phone'
          }.svg`}
        />
        {t('checkout.phone')}
      </Button>
      <div
        className={classNames('checkout-return')}
        role="button"
        tabIndex="0"
        onClick={() => setStage('pay')}
      >
        {t('checkout.return')}
      </div>
    </div>
  );
};

export default CheckoutContainer;
