/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';
import getStorage from '../utils/getStorage';
import clearStorage from '../utils/clearStorage';
import StatusContext from '../contexts/statusContext';
import Lng from './lng';

const Nav = () => {
  const { t } = useTranslation();
  const { stage, setStage } = useContext(StatusContext);

  const onLogout = async () => {
    setStage('login');
    clearStorage();
    window.location.replace(`${process.env.REACT_APP_ROUTE_PATH}/`);
  };

  clearTimeout(window.logoutTimmer);
  window.logoutTimmer = setTimeout(()=>{
    if(stage !== 'login'){
      onLogout();
    }
  }, 120 * 1000);

  return (
    <div
      className={classNames(
        'nav-container',
        `nav-container${stage !== 'login' ? '--show' : ''}`,
      )}
    >
      <img
        className={classNames('nav-logo')}
        alt="ncku-logo"
        src={`${process.env.REACT_APP_ROUTE_PATH}/white-ncku-logo-2.svg`}
        role="button"
        tabIndex="0"
      />
      <div className={classNames('nav-right-container')}>
        <div className={classNames('nav-name')}>
          {getStorage(i18n.language === 'en-US' ? 'ename' : 'name')}{' '}
          {t('nav.student')}
        </div>
        <button
          className={classNames('nav-btn')}
          type="button"
          onClick={onLogout}
        >
          {t('nav.logout')}
        </button>
        <div className={classNames('nav-lng')}>
          <Lng />
        </div>
      </div>
    </div>
  );
};

export default Nav;
