import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import StatusContext from '../contexts/statusContext';
import Nav from '../components/nav';
import Title from '../components/title';

const GeneralLayout = ({ children }) => {
  const { stage } = useContext(StatusContext);

  return (
    <main>
      <div
        className={classNames(
          'general-layout-circle',
          'general-layout-circle-red',
          `${stage !== 'login' ? 'general-layout-circle-red--move' : ''}`,
          `${stage === 'failed' ? 'general-layout-circle-red--disappear' : ''}`,
        )}
      />
      <div
        className={classNames(
          'general-layout-circle',
          'general-layout-circle-white',
          `${stage !== 'login' ? 'general-layout-circle-white--move' : ''}`,
          `${
            stage === 'failed' ? 'general-layout-circle-white--disappear' : ''
          }`,
        )}
      />
      <Nav />
      <Title />
      {children}
    </main>
  );
};

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GeneralLayout;
