import React from 'react';
import PropTypes from 'prop-types';

import FailedContainer from '../containers/failed';

const FailedPage = ({ match }) => (
  <FailedContainer payToken={match.params.payToken} />
);

FailedPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      payToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default FailedPage;
