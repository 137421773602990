import React, { useContext } from 'react';

import StatusContext from '../contexts/statusContext';
import LoginContainer from '../containers/login';
import PayContainer from '../containers/pay';
import CheckoutContainer from '../containers/checkout';

const Home = () => {
  const { stage } = useContext(StatusContext);

  const getStageComponent = () => {
    switch (stage) {
      case 'login':
        return <LoginContainer />;
      case 'pay':
        return <PayContainer />;
      case 'checkout':
        return <CheckoutContainer />;
      default:
        return <LoginContainer />;
    }
  };

  return getStageComponent();
};

export default Home;
