import React from 'react';
import PropTypes from 'prop-types';

import SuccessContainer from '../containers/success';

const SuccessPage = ({ match }) => (
  <SuccessContainer payToken={match.params.payToken} />
);

SuccessPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      payToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SuccessPage;
