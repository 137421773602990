import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tw from './zh-TW.json';
import en from './en-US.json';
import getStorage from '../utils/getStorage';

const dictionary = {
  'zh-TW': { translation: tw },
  'en-US': { translation: en },
};

// init setting
i18n.use(initReactI18next).init({
  resources: dictionary,
  lng: getStorage('lng') || 'zh-TW', // default language
  returnObjects: true, // allow array structure in json
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
