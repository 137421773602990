import React, { useRef, useCallback, useState } from 'react';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@material-ui/core';

import bills from '../tests/bills.json';

const testImageDownload = () => {
  const { t } = useTranslation();
  const [recieptURL, setRecieptURL] = useState('');
  const receiptRef = useRef();
  const onButtonClick = useCallback(async () => {
    if (receiptRef.current === null) {
      return;
    }

    toPng(receiptRef.current, { cacheBust: true }).then((dataUrl) => {
      const img = new Image();
      img.src = dataUrl;
      setRecieptURL(dataUrl);
    });
  }, [receiptRef]);

  return (
    <>
      {recieptURL ? (
        <img alt="" src={recieptURL} className="reciept-img" />
      ) : (
        <>
          <div className={classNames('receipt-container')}>
            <div className={classNames('receipt')} ref={receiptRef}>
              <img
                className={classNames('receipt-bg')}
                alt="receipt-bg"
                src={`${process.env.REACT_APP_ROUTE_PATH}/receipt-logo-light.png`}
              />
              <div className={classNames('receipt-title flex-col')}>
                國立成功大學
                <br />
                (National Cheng Kung University)
                <br />
                自動繳費機繳費證明單
                <br />
                (Payment certificate of Automatic Payment Machine)
                <br />
              </div>
              <div className={classNames('receipt-date')}>
                申請日期(Application Date)：2010/06/28
              </div>
              <table className={classNames('receipt-content')}>
                <colgroup>
                  <col span={1} style={{ width: '30%' }} />
                  <col span={1} style={{ width: '22.5%' }} />
                  <col span={1} style={{ width: '25%' }} />
                  <col span={1} style={{ width: '22.5%' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      收款單位名稱
                      <br />
                      Beneficiary unit
                    </td>
                    <td colSpan="3">
                      {bills.map((item) => `${item.dept_name} `)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      姓名
                      <br />
                      Full name
                    </td>
                    <td>王小明</td>
                    <td>
                      學號/職工編號
                      <br />
                      Student ID/Employ ID
                    </td>
                    <td>A12345678</td>
                  </tr>
                  <tr>
                    <td>
                      身分證字號
                      <br />
                      ID
                    </td>
                    <td colSpan="3">H123456789</td>
                  </tr>
                  <tr>
                    <td>
                      項目名稱
                      <br />
                      Item name
                    </td>
                    <td colSpan="3">
                      {bills.map((item) => `${item.item_name} `)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      繳費金額
                      <br />
                      Payment amount
                    </td>
                    <td colSpan="3">
                      {bills.reduce(
                        (sum, item) => sum + parseInt(item.apply_amt, 10),
                        0,
                      )}{' '}
                      元
                    </td>
                  </tr>
                  <tr>
                    <td>
                      備註
                      <br />
                      Payment method and notes
                    </td>
                    <td colSpan="3" className={classNames('remark')}>
                      繳費時間(Payment date)： 2020/02/02
                      <br />
                      繳費方式(Payment type)：信用卡支付
                      <br />
                      收執聯流水號：1213jife
                      <br />
                      (Serial number of payment receipt copy) <br />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={classNames('receipt-block')}>
                <div>
                  經手人：蔡孟君
                  <br />
                  CASHIER
                </div>
                <div>
                  主辦出納：謝宜芳
                  <br />
                  CHIEF CASHIER
                </div>
                <div>
                  主辦會計：王明洲
                  <br />
                  CHIEF ACCOUNTANT
                </div>
                <div>
                  機關長官：蘇慧貞
                  <br />
                  PRESIDENT
                </div>
              </div>
              <img
                className={classNames('proof-img')}
                alt="receipt-proof"
                src={`${process.env.REACT_APP_ROUTE_PATH}/receipt-proof2.png`}
              />
              <ul className={classNames('text-lft', 'receipt-remark')}>
                <li>
                  1、本證明書經影印或塗改無效。
                  <br />A photocopied document is void and bears the word COPY
                </li>
                <li>
                  2、本繳費證明專用章，僅供繳費者下載存證，倘有違法盜用者，應自負法律責任。
                  <br />
                  The proof of payment stamp is provided for download by payers
                  for reference only.Liability from any unauthorized or illegal
                  usage rests solely with the person responsible.
                </li>
                <li>
                  3、一旦申請退費，本繳費證明單即無效。
                  <br />
                  Once the payer applies for the fee refund, this proof will be
                  void.
                </li>
              </ul>
            </div>
          </div>
          <Button
            className={classNames('download')}
            type="button"
            onClick={onButtonClick}
          >
            {t('receipt.download')}
          </Button>
        </>
      )}
    </>
  );
};

export default testImageDownload;
