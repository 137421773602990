import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CheckoutItem = ({ index, name, value, memo }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={classNames('checkout-item-container')}>
      <div className={classNames('checkout-item-hint-container')}>
        <div className={classNames('checkout-item-index')}>
          {t('checkout.item')} ({index + 1})
        </div>
        <div className={classNames('checkout-item-hint-right')}>
          <div className={classNames('checkout-item-name')}>{name}</div>
          <button
            className={classNames('checkout-item-btn')}
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? '▲' : '▼'}
          </button>
        </div>
      </div>
      {isExpanded && (
        <>
          <div className={classNames('checkout-item-payment-container')}>
            <div className={classNames('checkout-item-sum')}>
              {t('checkout.total')}
            </div>
            <div className={classNames('checkout-item-value')}>${value}</div>
          </div>
          <div className={classNames('checkout-item-memo')}>{memo}</div>
        </>
      )}
    </div>
  );
};

CheckoutItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  memo: PropTypes.string.isRequired,
};

export default CheckoutItem;
