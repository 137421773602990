import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import getStorage from './utils/getStorage';
import StatusContext from './contexts/statusContext';
import GeneralLayout from './layouts/generalLayout';
import HomePage from './pages/home';
import testImageDownloadPage from './pages/testImageDownload';
import SuccessPage from './pages/success';
import FailedPage from './pages/failed';
import CompletePage from './pages/complete';

function App() {
  const [stage, setStage] = useState(
    getStorage('isLocal') &&
      getStorage('studentId') &&
      getStorage('identityId') &&
      getStorage('name')
      ? 'pay'
      : 'login',
  );

  return (
    <Switch>
      <StatusContext.Provider value={{ stage, setStage }}>
        <GeneralLayout>
          <Route
            exact
            path={`${process.env.REACT_APP_ROUTE_PATH}/`}
            component={HomePage}
          />
          <Route
            exact
            path={`${process.env.REACT_APP_ROUTE_PATH}/testImageDownload`}
            component={testImageDownloadPage}
          />
          <Route
            path={`${process.env.REACT_APP_ROUTE_PATH}/complete/:result`}
            component={CompletePage}
          />
          <Route
            path={`${process.env.REACT_APP_ROUTE_PATH}/success/:payToken`}
            component={SuccessPage}
          />
          <Route
            path={`${process.env.REACT_APP_ROUTE_PATH}/failed/:payToken`}
            component={FailedPage}
          />
        </GeneralLayout>
      </StatusContext.Provider>
    </Switch>
  );
}

export default App;
