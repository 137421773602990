import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import StatusContext from '../contexts/statusContext';

const Title = () => {
  const { t } = useTranslation();
  const { stage } = useContext(StatusContext);
  const [mainText, setMainText] = useState('');
  const [subText, setSubText] = useState('');

  useEffect(() => {
    switch (stage) {
      case 'login':
        setMainText(t('title.platform'));
        setSubText(t('title.slogan'));
        break;
      case 'pay':
        setMainText(t('title.pay'));
        setSubText('');
        break;
      case 'checkout':
        setMainText(t('title.checkout'));
        setSubText('');
        break;
      case 'creditCard':
        setMainText(t('title.credit_card'));
        setSubText('');
        break;
      case 'success':
        setMainText(t('title.download'));
        setSubText('');
        break;
      default:
        setMainText('');
        setSubText('');
        break;
    }
  }, [stage, t]);

  return (
    <div
      className={classNames(
        'title-container',
        `${stage !== 'login' ? 'title-container--login' : ''}`,
        `${stage === 'complete' ? 'title-container--disappear' : ''}`,
      )}
    >
      <img
        className={classNames(
          'title-img',
          `${stage === 'login' ? 'title-img--show' : ''}`,
        )}
        alt="NCKU"
        src={`${process.env.REACT_APP_ROUTE_PATH}/red-ncku-logo.svg`}
      />
      <div
        className={classNames(
          'title-main',
          `${stage === 'login' ? 'title-main--large' : ''}`,
        )}
      >
        {mainText}
      </div>
      <div
        className={classNames(
          'title-sub',
          `${stage === 'login' ? 'title-sub--show' : ''}`,
        )}
      >
        {subText}
      </div>
    </div>
  );
};

export default Title;
