import axios from './axios';

const queryOrder = async (body) => {
  try {
    const response = await axios.post('/QueryOrder', body);
    const { Status } = response.data;
    if (Status === 'S') {
      const { Message: info } = response.data;
      return info;
    } else {
      return null;
    }
  } catch (e) {
    alert(e);
    return null;
  }
};

export default queryOrder;
