/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button, CircularProgress } from '@material-ui/core';

// import testBills from '../tests/bills.json';
import i18n from '../i18n';
import setStorage from '../utils/setStorage';
import getStorage from '../utils/getStorage';
import loginAndGetBills from '../apis/loginAndGetBills';
import StatusContext from '../contexts/statusContext';
import PayItem from '../components/payItem';

const PayContainer = () => {
  const { t } = useTranslation();
  const { setStage } = useContext(StatusContext);
  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = () => {
    setStage('checkout');
    setStorage(
      'selected',
      bills.filter((item) => item.selected),
    );
  };

  const onClickBill = (index) => {
    bills[index].selected = !bills[index].selected;
    setBills([...bills]);
  };

  const getBills = bills.map((item, i) => (
    <PayItem
      name={i18n.language === 'en-US' ? item.item_ename : item.item_name}
      amount={item.apply_amt}
      date={dayjs(item.apply_date).format('YYYY/MM/DD')}
      memo={item.apply_memo}
      selected={bills[i].selected}
      onClick={() => onClickBill(i)}
    />
  ));

  useEffect(async () => {
    setIsLoading(true);
    const res = await loginAndGetBills({
      Local: getStorage('isLocal'),
      SID: getStorage('studentId'),
      PID: getStorage('identityId'),
    });
    if (res) {
      const tmp = [...res.bills];
      const paid = getStorage('paid') || [];
      paid.forEach((item) => {
        const index = tmp.findIndex(
          // eslint-disable-next-line camelcase
          ({ apply_no }) => apply_no === item.apply_no,
        );
        if (index !== -1) {
          tmp.splice(index, 1);
        }
      });
      setBills(tmp.map((item) => ({ ...item, selected: false })));
    }
    setIsLoading(false);

    /* Below is for testing */
    // setBills([...testBills]);
  }, []);

  useEffect(() => {
    setStorage('bills', bills);
  }, [bills]);

  return (
    <div
      className={classNames(
        'pay-container',
        `${bills.length === 0 ? 'pay-container--none' : ''}`,
      )}
    >
      <div className={classNames('pay-bills-container')}>{getBills}</div>
      {isLoading ? (
        <CircularProgress style={{ color: '#a31f34' }} />
      ) : bills.length === 0 ? (
        <>
          <div className={classNames('pay-none')}>{t('pay.none')}</div>
          <div className={classNames('pay-contact')}>{t('pay.contact')}</div>
        </>
      ) : (
        <Button
          type="button"
          disabled={bills.filter((item) => item.selected).length === 0}
          onClick={onConfirm}
        >
          {t('pay.confirm')}
        </Button>
      )}
    </div>
  );
};

export default PayContainer;
