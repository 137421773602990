import axios from './axios';

const pay = async (body) => {
  try {
    const response = await axios.post('/Pay', body);
    const { Status, Message: url } = response.data;
    if (Status === 1) {
      return url;
    } else {
      alert("無法取得收款資料  Can't fetch data");
      return null;
    }
  } catch (e) {
    alert(e);
    return null;
  }
};

export default pay;
