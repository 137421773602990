import React from 'react';
import classNames from 'classnames';

import setStorage from '../utils/setStorage';
import i18n from '../i18n';

const Lng = () => {
  const setLng = (lng) => {
    i18n.changeLanguage(lng);
    setStorage('lng', lng);
  };

  return (
    <>
      <button
        className={classNames('lng-btn', 'lng-btn-en')}
        type="button"
        onClick={() => setLng('en-US')}
      >
        EN
      </button>
      <button
        className={classNames('lng-btn', 'lng-btn-zh')}
        type="button"
        onClick={() => setLng('zh-TW')}
      >
        中文
      </button>
    </>
  );
};

export default Lng;
