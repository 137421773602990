import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, CircularProgress } from '@material-ui/core';

import i18n from '../i18n';
import StatusContext from '../contexts/statusContext';
import getStorage from '../utils/getStorage';
import queryOrder from '../apis/queryOrder';

const FailedContainer = ({ payToken }) => {
  const { t } = useTranslation();
  const { setStage } = useContext(StatusContext);
  const [time, setTime] = useState(5);
  const [hint, setHint] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const back = () => {
    setStage('pay');
    window.location.replace(`${process.env.REACT_APP_ROUTE_PATH}/`);
  };

  useEffect(() => {
    setStage('failed');
    setInterval(() => {
      setTime((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
  }, []);

  useEffect(() => {
    if (i18n.language === 'zh-TW') {
      setHint(
        `${time}秒鐘後將自動跳轉回待繳項目頁面，若未跳出請按下方按鈕自行跳轉`,
      );
    } else {
      setHint(
        `It'll will automatically redirect to home page in ${time} seconds. If it doesn't, click the button below`,
      );
    }
    if (time === 0) back();
  }, [time]);

  useEffect(async () => {
    setIsLoading(true);
    const res = await queryOrder({
      SID: getStorage('studentId'),
      PayToken: payToken,
    });
    if (res) {
      window.location.replace(
        `${process.env.REACT_APP_ROUTE_PATH}/success/${payToken}`,
      );
    } else setIsLoading(false);
  }, []);

  if (isLoading) {
    return <CircularProgress style={{ color: '#a31f34' }} />;
  }

  return (
    <div className={classNames('failed-container')}>
      <div className={classNames('failed-title')}>{t('failed.failed')}</div>
      <div className={classNames('failed-hint')}>{hint}</div>
      <Button type="button" onClick={() => back()}>
        {t('failed.back')}
      </Button>
    </div>
  );
};

FailedContainer.propTypes = {
  payToken: PropTypes.string.isRequired,
};

export default FailedContainer;
