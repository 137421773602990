/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PayItem = ({ name, amount, date, memo, selected, onClick }) => (
  <div
    className={classNames('pay-item-container')}
    onClick={onClick}
    role="button"
    tabIndex="0"
  >
    <div className={classNames('pay-item-left-container')}>
      <div
        className={classNames(
          'pay-item-left-select',
          `${selected ? 'pay-item-left-select--selected' : ''}`,
        )}
      >
        {selected ? '✔' : ''}
      </div>
      <div className={classNames('pay-item-left-text-container')}>
        <div className={classNames('pay-item-left-name')}>{name}</div>
        <div className={classNames('pay-item-left-date')}>{date}</div>
        <div className={classNames('pay-item-left-memo')}>{memo}</div>
      </div>
    </div>
    <div className={classNames('pay-item-amount')}>${amount}</div>
  </div>
);

PayItem.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  memo: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PayItem;
