import axios from './axios';

const loginAndGetBills = async (body) => {
  try {
    const response = await axios.post('/LoginAndGetBill', body);
    const { Status } = response.data;
    if (Status === 1) {
      const {
        Message: bills,
        StudentInfo: { stu_name: name, stu_ename: ename },
      } = response.data;
      return { bills, name, ename };
    } else {
      alert('登入失敗  Login failed');
      return null;
    }
  } catch (e) {
    alert(e);
    return null;
  }
};

export default loginAndGetBills;
